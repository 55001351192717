import * as React from "react";
import styled from "styled-components";
import { GREY } from "../../utils/colors";
import { SIDEBAR_COLLAPSED, SIDEBAR } from "../../utils/sizes";
import * as breakpoints from "../../utils/breakpoint";
import CollapseButton from "../FinaSidebar/components/CollapseButton";
import { useWindowSize } from "src/hooks/useWindowSize";
import { antd_breakingPts } from "src/utils/constants/media";

interface IFinaContentProps {
  collapse: boolean;
  setCollapse: (collpase: boolean) => void;
  isMobile: boolean;
  children?: React.ReactNode;
  headerContent?: any;
}

const Content = styled.section<{ collapse: boolean }>`
  background-color: ${GREY};
  margin-left: ${({ collapse }) => (collapse ? SIDEBAR_COLLAPSED : SIDEBAR)};
  transition: margin-left 0.5s ease;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  @media only screen and (max-width: ${breakpoints.MOBILE}) {
    // margin-left: 10;
  }
`;

const Main = styled.div<{ width: number }>`
  flex-grow: 1;
  padding: ${({ width }) => (width < antd_breakingPts.sm ? "4px" : "16px")};
  display: flex;
`;

const Header = styled.header`
  height: 48px;
  background-color: white;
  box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.08);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
`;

const FinaContent: React.FunctionComponent<IFinaContentProps> = ({
  collapse,
  setCollapse,
  isMobile,
  children,
  headerContent,
}) => {
  const { width } = useWindowSize();

  return (
    <Content collapse={collapse}>
      <Main width={width}>{children}</Main>
    </Content>
  );
};

export default React.memo(FinaContent);
