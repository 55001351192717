import { useQuery } from "react-query";
import axiosInstance from "src/utils/axiosInstance";

const GET_BROKERAGE_POSITION_BY_SYMBOLE_ENDPOINT =
  "dashboard/direct-orders/positions";

const fetshBrokeragePositionBySymbole = async (symbol: string) => {
  const {
    data: { data: position },
  } = await axiosInstance.get(GET_BROKERAGE_POSITION_BY_SYMBOLE_ENDPOINT, {
    baseURL: "v2",
    params: { page_size: 10, page_number: 1, flag: "OPEN", symbol: symbol },
  });
  return position;
};

export const useBrokeragePositionBySymbol = (symbol: string) => {
  return useQuery(["brokeragePositions", symbol], () =>
    fetshBrokeragePositionBySymbole(symbol),
  );
};
