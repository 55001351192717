import React, { useState } from "react";
import { StyledFormSection, StyledPredictionForm } from "../predictions";
import { FrequencyRadioField } from "../components/GoalAmountFormInputs";
import TimeDurationInput from "./Duration";
import AmountPredictionChart from "../components/AmountPredictionChart";
import { useDispatch, useSelector } from "react-redux";
import { RootStateType } from "src/store/rootReducer";
import AmountSlider from "../components/AmountSlider";
import { updateGoalField } from "src/store/Goal-Based/GoalAmountSlice";
import { useGetGoalAmountStatsQuery } from "src/store/Goal-Based/goalBasedApi";
import { GoalData, GoalStats } from "src/store/Goal-Based/goal";
import { message } from "antd";

export enum Fields {
  period = "period",
  startDate = "start_date",
  endDate = "end_date",
  contributionType = "contribution_type",
  initialAmount = "initial_amount",
  goalAmount = "goal_amount",
}

const GoalForm: React.FC = () => {
  const goalFormValues = useSelector(
    (state: RootStateType) => state.GoalAmountReducer,
  );
  const dispatch = useDispatch();

  const { data: stats, isFetching } = useGetGoalAmountStatsQuery({
    input: goalFormValues,
  });

  const [isMessageOpen, setIsMessageOpen] = useState(false);

  const showMessage = () => {
    if (!isMessageOpen) {
      setIsMessageOpen(true);
      message.info("Initial amount should be less than goal amount!", 4, () => {
        setIsMessageOpen(false);
      });
    }
  };

  const handleInitialAmountChange = (number: number) => {
    const goalAmount = Number(goalFormValues[Fields.goalAmount]);
    if (number > goalAmount) {
      showMessage();
    } else
      dispatch(
        updateGoalField({
          name: Fields.initialAmount,
          value: number.toString(),
        }),
      );
  };

  const handleGoalAmountChange = (number: number) => {
    const initialAmount = Number(goalFormValues[Fields.initialAmount]);
    if (number < initialAmount) {
      showMessage();
    } else
      dispatch(
        updateGoalField({
          name: Fields.goalAmount,
          value: number.toString(),
        }),
      );
  };

  return (
    <StyledPredictionForm>
      <StyledFormSection>
        <AmountSlider
          titles={{
            optional: true,
            title: "Investment Initial Amount",
            subtitle: "If I Would Like to Start with:",
          }}
          name={Fields.initialAmount}
          max={50000}
          value={Number(goalFormValues[Fields.initialAmount])}
          onChange={handleInitialAmountChange}
        />
      </StyledFormSection>
      <StyledFormSection>
        <AmountSlider
          titles={{
            title: "Final / Goal Amount",
            subtitle: "If I Would Like To Get At the End:",
          }}
          max={1000000}
          value={Number(goalFormValues[Fields.goalAmount])}
          name={Fields.goalAmount}
          onChange={handleGoalAmountChange}
        />
      </StyledFormSection>
      <StyledFormSection>
        <TimeDurationInput<GoalData>
          updateField={updateGoalField}
          value={goalFormValues[Fields.period]}
          startDate={goalFormValues[Fields.startDate]}
        />
        <FrequencyRadioField<GoalData>
          updateField={updateGoalField}
          value={goalFormValues[Fields.contributionType]}
        />
      </StyledFormSection>
      <AmountPredictionChart
        titles={{
          title: "Goal Contribution",
          subtitle: "I Should Expect To Contribute:",
        }}
        data={stats as GoalStats}
        isLoading={isFetching}
      />
    </StyledPredictionForm>
  );
};
export default GoalForm;
