import { InputNumber } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import Slider from "./Slider";
import { SliderTitle, SliderTitleProps } from "./SliderTitle";
import { theme } from "src/theme/theme";

interface AmountSliderProps {
  className?: string;
  titles: SliderTitleProps;
  value: number;
  name: string;
  max: number;
  onChange: (newValue: number) => void;
}

const StyledAmount = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
  @media (max-width: 570px) {
    margin-top: 2em;
    flex-direction: column-reverse;
  }
`;

export const StyledAmountInput = styled(InputNumber)`
  &.ant-input-number {
    border-radius: 0.3125rem;
    border: 1px solid ${theme.colors.primary[500]};
    background:${theme.colors.primary[25]};
  }
  position: relative;

  &::before {
    content: "$";
    position: absolute;
    left: 0.625rem;
    top: 0.4375rem;
    pointer-events: none;
  }

  .ant-input-number-input {
    padding: 0.375rem 0 0 1.3125rem;
    color: ${theme.colors.primary[400]};
  }
  width: 10rem;
  height: 2.8em;
  @media (max-width: 570px) {
    width: 100%;
  }
`;

const AmountSlider: React.FC<AmountSliderProps> = ({
  titles,
  value,
  max,
  name,
  onChange,
  className,
}) => {
  const [amount, setAmount] = useState(value);

  return (
    <div className={className}>
      <SliderTitle {...titles} />
      <StyledAmount>
        <Slider
          style={{ flex: "1" }}
          value={amount}
          min={0}
          defaultValue={0}
          max={max}
          onChange={setAmount}
          onAfterChange={onChange}
        />
        <StyledAmountInput
          value={amount}
          name={name}
          min={0}
          defaultValue={2}
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
          onChange={(value) => {
            setAmount(value);
            onChange(value);
          }}
        />
      </StyledAmount>
    </div>
  );
};

export default AmountSlider;
