import { FC, useState } from "react";
import { Row, Col, Divider, Typography, InputNumber } from "antd";
import { StockTextInputProps } from "../../utils/interfaces";
import { theme } from "src/theme/theme";

const { Text } = Typography;

export const StockTextInput: FC<StockTextInputProps> = ({
  stockPrice,
  leftLabel,
  rightLabel,
  onChange,
  maxQty,
}) => {
  const [amount, setAmount] = useState(1 * stockPrice);

  const handleChangeSharesNumber = (value: number) => {
    setAmount(value * stockPrice);
    onChange(value);
  };

  return (
    <>
      <Row style={{ padding: "0px 10px" }}>
        <Col span={12}>
          <Text style={{ color: theme.colors.primary[500] }}>{leftLabel}</Text>
        </Col>
        <Col style={{ paddingLeft: 35 }} span={12}>
          <Text style={{ color: theme.colors.primary[500] }}>{rightLabel}</Text>
        </Col>
      </Row>
      <Row
        justify="center"
        align="middle"
        style={{
          width: "100%",
          height: 40,
          border: `1px solid ${theme.colors.primary[500]}`,
        }}
      >
        <Col span={11}>
          <InputNumber
            formatter={(value) => Math.round(value).toString()}
            parser={(value) => Math.round(+value)}
            bordered={false}
            defaultValue={1}
            min={1}
            max={maxQty}
            style={{ width: "100%", color: theme.colors.primary[500] }}
            onChange={handleChangeSharesNumber}
          />
        </Col>
        <Col span={1} style={{ textAlign: "center" }}>
          <Divider
            type="vertical"
            style={{ height: "30px", borderColor: theme.colors.primary[500] }}
          />
        </Col>
        <Col span={11}>
          <InputNumber
            readOnly={true}
            bordered={false}
            style={{ width: "100%", color: theme.colors.primary[500] }}
            value={Number(amount.toFixed(2))}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => parseFloat(value!.replace(/\$\s?|(,*)/g, ""))}
          />
        </Col>
      </Row>
    </>
  );
};
