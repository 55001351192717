import React from "react";

import { Container, DetailsContainer, Details, Main } from "./styles/layout";

// TODO type this interface
interface LayoutProps {
  customDetail?: any;
  isMobile?:boolean;
  children?: any;
}

export const Layout: React.FC<LayoutProps> = ({ customDetail, isMobile, children }) => {
  return (
    <Container $disableDetails={!customDetail} isMobile={isMobile}>
      {customDetail && (
        <DetailsContainer>
          <Details>{customDetail()}</Details>
        </DetailsContainer>
      )}

      <Main $detailsDisabled={!customDetail}>{children}</Main>
    </Container>
  );
};
