import React from "react";
import styled from "styled-components";

import ItemGroup from "./ItemGroup";
import { IRouteItem, settings } from "src/components/FinaLayout/routes";
import { useClient } from "src/store/hooks";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { StyledUsername, StyledUsernameText } from "./FinaSidebar.style";

const Group = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: ${({ collapse }) => (collapse ? "flex-start" : "center")};
  justify-content: ${({ justifyContent }) => justifyContent};
  flex-wrap: nowrap;
  font-size: 1rem;
  overflow-y: ${({ scroll }) => (scroll ? "cursor" : "hidden")};
  overflow-x: hidden;
`;

type Props = { routes?: IRouteItem; collapse: boolean };

const Menu: React.FC<Props> = ({ routes, collapse }) => {
  const { data } = useClient();  
  return (
    <>
      <Group collapse={collapse}>
        <Group scroll={true}>
          {Object.keys(routes).map((route) => (
            <ItemGroup
              collapse={collapse}
              key={route}
              title={route.toUpperCase()}
              items={routes[route]}
              email={data?.email?.value}
            />
          ))}
          <ItemGroup collapse={collapse} items={settings} title="ACCOUNT" />
        </Group>
      </Group>
      <StyledUsername>
        <Avatar
          size="default"
          style={{ cursor: "pointer" }}
          icon={<UserOutlined />}
          src={data?.picture}
          alt="Avatar"
        />
        {!collapse && (
          <StyledUsernameText>
            {data?.name?.first} {data?.name?.last}
          </StyledUsernameText>
        )}
      </StyledUsername>
    </>
  );
};

export default Menu;
