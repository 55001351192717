import {
  Card,
  Col,
  Row,
  Table,
  Typography,
  Pagination,
  Spin,
  Badge,
  Space,
  Empty,
  Tooltip,
} from "antd";
import { FC, useEffect, useState } from "react";
import { userInterface } from "src/interfaces/Client";
import CardTitle from "./components/CardTitle";
import { SymbolOverViewWidget } from "./widgets/SymbolOverview";
import {
  ORDERS_PAGE_SIZE,
  useBrokerageOrderByTickerID,
} from "./api/useGetOrders";
import { useBrokeragePositions } from "./api/useBrokeragePositions";
import { fixNumber } from "src/utils/number";
import { useParams } from "react-router";
import { useGetTickerById } from "./api/useGetTickerById";
import { ordersColumns } from "./utils/orderColumns";
import { InvestModal } from "./Layouts/InvestModal";
import { TradingForm } from "./components/StockForm";
import { StockButton } from "./components/StockButton";
import { useCancelOrder } from "./api/useCancelOrder";
import { useQueryClient } from "react-query";
import { ButtonTheme } from "src/components/Button/ButtonTheme";
import { useBrokeragePositionBySymbol } from "./api/useBrokeragePositionBySymbol";

interface BrokerageOrdersProps {
  user: userInterface;
}

const { Text, Title } = Typography;

interface Position {
  ticker: {
    symbol: string;
    market: {
      market: string;
    };
  };
  quantity: number;
  price: number;
  avg_price: number;
  value: number;
  cost: number;
  unrealized_pnl_pct: number;
  realized_pnl_pct: number;
}

const PositionInfos = ({ tickerId }) => {
  const [position, setPosition] = useState<Position>();
  const [chunkedPostion, setChunkedPosition] = useState<any[] | null>(null);

  const { data: positions, isLoading: isPositionsLoading } =
    useBrokeragePositions();

  useEffect(() => {
    if (positions && tickerId) {
      const foundedPosition = positions.find(
        (position) => position.ticker_id == tickerId,
      );
      setPosition(foundedPosition);
    }
  }, [positions]);

  useEffect(() => {
    if (position) {
      const chunkedArray = [
        [
          { label: "Status", prefix: <Badge color="green" />, value: "OPEN" },
          { label: "Security", prefix: "", value: position.ticker?.symbol },
          {
            label: "Market",
            prefix: "",
            value: position.ticker?.market?.market,
          },
        ],
        [
          { label: "Quantity", prefix: "", value: position.quantity },
          {
            label: "Avg Cost",
            prefix: "$",
            value: fixNumber(position.avg_price, 3),
          },
          {
            label: "Last Price",
            prefix: "$",
            value: fixNumber(position.price, 3),
          },
        ],
        [
          {
            label: "Market Value",
            prefix: "$",
            value: fixNumber(position.value, 3),
          },
          {
            label: "Actual Cost",
            prefix: "$",
            value: fixNumber(position.cost, 3),
          },
          {
            label: "Unrealized PL",
            suffix: "%",
            value: fixNumber(position.unrealized_pnl_pct * 100, 3),
          },
        ],
        [
          {
            label: "Relized PL",
            suffix: "%",
            value: fixNumber(position.realized_pnl_pct * 100, 3),
          },
          {
            label: "Total PL",
            suffix: "%",
            value:
              fixNumber(position.unrealized_pnl_pct * 100, 3) +
              fixNumber(position.realized_pnl_pct * 100, 3),
          },
        ],
      ];
      setChunkedPosition(chunkedArray);
    }
  }, [position]);

  if (isPositionsLoading) return <Spin />;
  if (chunkedPostion)
    return (
      <Row gutter={[16, 16]}>
        {chunkedPostion.map((chunk, index) => (
          <Col
            key={index}
            xs={24}
            sm={12}
            md={12}
            lg={8}
            xl={6}
            xxl={6}
            style={{
              borderRight:
                index !== chunkedPostion.length - 1
                  ? "1px solid #E0E3EB"
                  : "none",
              padding: "0px 2rem",
            }}
          >
            {chunk.map((item: any, index: number) => (
              <Row key={index}>
                <Col span={12}>
                  <Text type="secondary">{item.label}</Text>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  <Text style={{ fontWeight: 400 }}>
                    {item.prefix}
                    {item.value}
                    {item.suffix}
                  </Text>
                </Col>
              </Row>
            ))}
          </Col>
        ))}
      </Row>
    );
  return <Empty />;
};

const BrokerageOrders: FC<BrokerageOrdersProps> = ({ user }) => {
  // const { param } = useParams<{ param: string }>();
  const { ticker_id, symbol } = useParams<{
    ticker_id: string;
    symbol: string;
  }>();

  const queryClient = useQueryClient();

  const [orders, setOrders] = useState<any[]>([]);
  const [side, setSide] = useState<null | string>(null);
  const [showInvestModal, setShowInvestModal] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [selectedId, setSelectedId] = useState<number | null>(null);

  const { data: dataOrders, isLoading: isOrdersLoading } =
    useBrokerageOrderByTickerID({ currentPage, ticker_id });

  const { data: ticker, isLoading: isTickerLoading } =
    useGetTickerById(ticker_id);
  const {
    mutate: cancelOrder,
    isLoading: cancelOrderLoading,
    isSuccess: isCancelSuccess,
  } = useCancelOrder();
  const {
    data: position,
    isLoading: isPositionLoding,
    isError: isPositionError,
    error: positionError,
  } = useBrokeragePositionBySymbol(symbol);

  useEffect(() => {
    if (dataOrders) {
      setOrders(dataOrders?.data);
      setTotalPages(Math.ceil(dataOrders?.count / ORDERS_PAGE_SIZE));
    }
  }, [dataOrders]);

  useEffect(() => {
    if (isCancelSuccess)
      queryClient.invalidateQueries({ queryKey: ["brokerageOrders"] });
  }, [isCancelSuccess]);

  const handleShowInvestModal = () => {
    setShowInvestModal(!showInvestModal);
  };

  const handleSelectSide = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSide(event.currentTarget.value);
    setShowInvestModal(!showInvestModal);
  };

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  const onCancelOrder = (record: any, id: number) => {
    setSelectedId(id);
    cancelOrder(record?.id);
  };

  return (
    <Card
      loading={isTickerLoading}
      style={{ width: "100%" }}
      title={<CardTitle title="Security Orders" redirectUrl="/Dashboard" />}
      extra={
        <Space>
          <Tooltip
            placement="top"
            title={
              position?.length === 0
                ? "There are no items available for sale on this ticket."
                : ""
            }
          >
            <ButtonTheme
              value="sell"
              onClick={handleSelectSide}
              size="middle"
              primary={true}
              disabled={position?.length === 0}
            >
              Sell
            </ButtonTheme>
          </Tooltip>
          <ButtonTheme
            value="buy"
            onClick={handleSelectSide}
            size="middle"
            primary={true}
          >
            Buy
          </ButtonTheme>
        </Space>
      }
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <SymbolOverViewWidget symbol={ticker?.symbol} />
        </Col>
        <Col span={24}>
          <Title level={5} style={{ padding: "16px 8px", margin: 0 }}>
            Position
          </Title>
          <Card>
            <PositionInfos tickerId={3} />
          </Card>
        </Col>
        <Col span={24}>
          <Table
            size="small"
            loading={isOrdersLoading}
            title={() => <Title level={5}>Orders</Title>}
            columns={ordersColumns(
              onCancelOrder,
              cancelOrderLoading,
              selectedId,
            )}
            dataSource={orders}
            scroll={{ x: 2300 }}
            pagination={false}
            summary={(pageData) => {
              let totalFillQty = 0;
              let totalFillValue = 0;
              pageData.forEach(({ order_side, fill_quantity, fill_value }) => {
                totalFillQty +=
                  order_side === "BUY" ? fill_quantity : -fill_quantity;
                totalFillValue +=
                  order_side === "BUY" ? fill_value : -fill_value;
              });
              return (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <Text strong>Total</Text>
                  </Table.Summary.Cell>
                  {[...Array(7)].map((_, index) => (
                    <Table.Summary.Cell index={index + 1} />
                  ))}
                  <Table.Summary.Cell index={8}>
                    <Text strong>{totalFillQty}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={9} />
                  <Table.Summary.Cell index={10}>
                    <Text strong>{fixNumber(totalFillValue, 3)}</Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              );
            }}
          />
          <Pagination
            current={currentPage}
            total={totalPages * ORDERS_PAGE_SIZE}
            pageSize={ORDERS_PAGE_SIZE}
            showSizeChanger={false}
            onChange={handlePaginationChange}
            style={{ marginTop: "16px", textAlign: "right" }}
          />
        </Col>
      </Row>
      <InvestModal
        setModalVisible={handleShowInvestModal}
        isModalVisible={showInvestModal}
      >
        <TradingForm
          side={side}
          stockInfos={{
            id: ticker?.id,
            symbol: ticker?.symbol,
            name: ticker?.portal_name,
            maxQty: position?.[0]?.quantity,
          }}
          onCancel={handleShowInvestModal}
        />
      </InvestModal>
    </Card>
  );
};

export default BrokerageOrders;
