import React, { useState, useCallback, useMemo } from "react";
import { DatePicker } from "antd";
import Slider from "../components/Slider";
import moment from "moment";
import DurationInput from "../components/DurationInput";
import styled from "styled-components";
import { Fields } from "../components/GoalAmountFormInputs";
import {
  SliderTitle,
  SliderTitleProps,
  StyledSubtitle,
} from "../components/SliderTitle";
import { useDispatch } from "react-redux";
import { UpdateFieldProps } from "src/store/Goal-Based/goal";
import { theme } from "src/theme/theme";

const MAX_YEARS = 30;
const MAX_TOTAL_MONTHS = MAX_YEARS * 12;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8em;
`;

const StyledDurationSliderInput = styled.div`
  margin-top: -0.8em;
  display: flex;
  gap: 1em;
  @media (max-width: 570px) {
    margin-top: 1em;
    flex-direction: column-reverse;
  }
`;

const StyledDates = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 6px;
  @media (max-width: 570px) {
    flex-direction: column;
  }
`;

export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  border-radius: 5px;
  height: 2.8em;
  border-color: ${theme.colors.primary[500]};
  background-color: ${theme.colors.primary[25]};
  input {
    color: ${theme.colors.primary[500]};
  }
`;

export const StyledDate = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const titles: SliderTitleProps = {
  title: "Investment Duration",
  subtitle: "If I Would Like To Contribute For:",
};

export default function TimeDurationInput<T>(props: UpdateFieldProps<T>) {
  const { updateField, value, startDate: initialStartDate } = props;
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(moment(initialStartDate));
  const [years, setYears] = useState(Math.floor(Number(value) / 12));
  const [months, setMonths] = useState(Number(value) % 12);
  const [duration, setDuration] = useState(Number(value));

  const endDate = useMemo(() => {
    if (startDate) {
      const date = moment(startDate).add({ years, months });
      dispatch(
        updateField({
          name: Fields.endDate,
          value: date.format("YYYY-MM-DDTHH:mm:ss.SSSSSS"),
        }),
      );
      return date;
    }
    return null;
  }, [startDate, years, months]);

  const disabledDate = useCallback((current) => {
    return current && current < moment().startOf("day");
  }, []);

  const handleStartDateChange = useCallback((date) => {
    setStartDate(date);
    dispatch(
      updateField({
        name: Fields.startDate,
        value: date.format("YYYY-MM-DDTHH:mm:ss.SSSSSS"),
      }),
    );
  }, []);

  const handleYearsChange = useCallback(
    (value) => {
      setYears(value);
      setDuration(value * 12 + months);
      dispatch(
        updateField({ name: Fields.period, value: value * 12 + months }),
      );
    },
    [months],
  );

  const handleMonthsChange = useCallback(
    (value) => {
      setMonths(value);
      setDuration(value + years * 12);
      dispatch(updateField({ name: Fields.period, value: value + years * 12 }));
    },
    [years],
  );

  const handleSliderChange = useCallback((value) => {
    dispatch(updateField({ name: Fields.period, value: value }));
    setYears(Math.floor(value / 12));
    setMonths(value % 12);
  }, []);

  return (
    <StyledContainer>
      <SliderTitle {...titles} />
      <StyledDurationSliderInput>
        <Slider
          style={{ flex: "1" }}
          min={6}
          name={Fields.period}
          max={MAX_TOTAL_MONTHS}
          value={duration}
          onAfterChange={handleSliderChange}
          onChange={setDuration}
        />
        <DurationInput
          years={Math.floor(duration / 12)}
          months={duration % 12}
          handleMonthsChange={handleMonthsChange}
          handleYearsChange={handleYearsChange}
        />
      </StyledDurationSliderInput>
      <StyledDates>
        <StyledDate>
          <StyledSubtitle>Starting From:</StyledSubtitle>
          <StyledDatePicker
            value={startDate}
            onChange={handleStartDateChange}
            disabledDate={disabledDate}
          />
        </StyledDate>
        <StyledDate>
          <StyledSubtitle style={{ color: "grey" }}>Ending On:</StyledSubtitle>
          <DatePicker
            style={{ borderRadius: "5px", width: "100%", height: "2.8em" }}
            value={endDate}
            disabled
          />
        </StyledDate>
      </StyledDates>
    </StyledContainer>
  );
}
