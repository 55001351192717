
import { useQuery } from "react-query";
import axiosInstance from "src/utils/axiosInstance";

const fetchTicker = async (ticker_id: string) => {
    const GET_TICKER_BY_ID_ENDPOINT = `dashboard/tickers`
    const { data: ticker } = await axiosInstance.get(`${GET_TICKER_BY_ID_ENDPOINT}/${ticker_id}`,
        {
            baseURL: "v2",
            headers: { "X-Fields": ["id", "symbol", "portal_name", "instrument_nature", "market"] }
        });
    return ticker
}

export const useGetTickerById = (ticker_id: string) => {
    return useQuery(["ticker", ticker_id], () => fetchTicker(ticker_id));
};